.sponsor-wrapper {
	margin-top: 90px;
}

.sponsor-entry {
	min-height: 200px;
	width: 100%;
	border: 1px solid #ddd;
	position: relative;
	margin-bottom: 30px;
	background: $white;
}

.sponsor-entry-background {
	min-height: 200px;
	width: 100%;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
}

.sponsor-entry-info {
	position: absolute;
	padding: 20px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: $secondary-color;
	z-index: 4;
	text-align: center;
	color: $white;
	font-size: 25px;
	border: 10px solid $white;
	opacity: 0;
	transition: 0.5s;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;

	&:hover {
		opacity: 1;
		transition: 0.5s;
	}
}