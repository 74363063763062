.frontpage-body {
	.navbar.navbar-transparent {
		background: transparent;
	}
}

.navbar {

   .navbar-collapse.in {
     overflow-y: initial;
   }

	.navbar-main {
		float: left;

		li {
          &:nth-child(1) {
            animation-delay: 0.1s;
          }
          &:nth-child(2) {
            animation-delay: 0.3s;
          }
          &:nth-child(3) {
            animation-delay: 0.5s;
          }
          &:nth-child(4) {
            animation-delay: 0.7s;
          }
          &:nth-child(5) {
            animation-delay: 0.9s;
          }
			&:nth-child(6) {
				animation-delay: 1.1s;
			}
		}
	}

	.navbar-toggle {
		 margin-top: 23px;
         margin-right: 0;

         .icon-bar {
             background: $secondary-color;
         }
     }

	&.navbar-primary {
		background: rgba(0, 0, 0, 0.9);
		height: 80px;
	    border-top-width: 0px;
	    border-bottom-width: 0px;
	    margin-bottom: 0px;
	    border-radius: 0;
	    position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    z-index: 10;

		.nav > li > a:hover,
		.nav > li > a:focus {
			background: $nav-background-hover;
		}

		.nav > li.selected {
			background: $nav-background-hover;
		}

		.navbar-nav > li > a {
			padding-top: 30px;
			padding-bottom: 30px;
			height: 80px;
			color: $white;
			font-family: $primary-font;
			font-weight: 400;
			text-transform: uppercase;
			padding-left: 15px;
			padding-right: 15px;
		}

		.navbar-nav {
			.nav-social {
				font-size: 20px;

				a {
					padding-left: 30px;
					padding-right: 30px;
					border-left: 1px solid #6f6f6f;
				}
			}
		}
	}

	&.navbar-fixed {
		position: fixed;
		z-index: 12;
	    animation-name: stickynav;
	    animation-duration: 0.6s;
	}

	&.navbar-transparent {
		background: rgba(0, 0, 0, 0.5);
		@media(max-width: $screen-xs-max) {
			background: rgba(0, 0, 0, 0.9);
		}

		&.navbar-fixed {
			background: rgba(0, 0, 0, 0.9);
		}

		.nav > li.selected {
          @media (min-width: $screen-xs-min) {
            background: rgba(0, 0, 0, 0.0);

			a {
				color: $secondary-color;
			}
          }
		}

		.navbar-nav .nav-social {

			a {
				border-left: 0;
			}
		}

		.nav > li > a:hover,
		.nav > li > a:focus {
			background: transparent;
			color: $secondary-color;
		}
	}
}

.navbar-logo {
	margin-top: 7px;
	margin-left: 20px;
	margin-right: 20px;
}

@keyframes stickynav {
    from {top: -70px;}
    to {top: 0;}
}

@media (max-width: $screen-md-max) {

	.navbar {
		.navbar-main {
			float: right;
		}
	}
}

@media (max-width: $screen-sm-max) {

	.nav-close-btn {
	    position: fixed;
	    right: 13px;
	    top: 18px;
	    color: #fff;
	    z-index: 100;
	    font-size: 30px;
	}

	.navbar-brand {
		padding-right: 0;
	}

	.navbar-logo {
		margin-left: 0;
		margin-right: 0;
	}

	.navbar {
		&.navbar-primary {
			.navbar-nav > li > a {
		    	padding: 30px 10px;
			}
		}
	}
}

@media (max-width: $screen-sm-max) and (min-width: $screen-xs-min) {

  .navbar-brand > img {
    width: 210px;
    margin-top: 11px;
  }

}

@media (max-width: $screen-xs-max) {

	.nav-close-btn {
	    position: fixed;
	    right: 13px;
	    top: 18px;
	    color: #fff;
	    z-index: 100;
	    font-size: 30px;
	}

	.navbar-brand {
		padding-right: 0;
		width: 80%;
		margin-top: 0;
	}

	.navbar-logo {
		margin-left: 0;
		margin-right: 0;
	}

	.navbar {
		&.navbar-primary {
			.navbar-nav > li > a {
		    	padding: 30px 10px;
			}
		}

		&.navbar-transparent {
			.nav > li > a {
				&:hover {
					color: $white;
				}
				&:focus {
					color: $white;
				}
			}
		}

		.navbar-main {
			float: right;
			position: fixed;
			background: rgba(0, 0, 0, 0.85);
			z-index: 20;
			left: 0px;
			top: 0px;
			right: 0px;
			bottom: 0px;
			padding: 0;
		    padding-top: calc(50vh - 240px);
			text-align: center;
			margin: 0;
		}
	}
}