.frontpage-about {
	color: $white;
	background: $grey-light;
	text-align: center;
	width: 100%;
	float: left;
	position: relative;
	font-family: $primary-font;
	font-weight: 300;
	height: 400px;

	&-inner {
		padding-top: 100px;
		padding-bottom: 100px;
	}

	&-gradient {
	    position: absolute;
	    left: 15%;
	    width: 70%;
	    height: 500px;
	    top: -200px;
		background: $primary-color;
		-webkit-transform: skew(27deg, 6deg);
		-moz-transform: skew(27deg, 6deg);
		-ms-transform: skew(27deg, 6deg);
		-o-transform: skew(27deg, 6deg);
		transform: skew(27deg, 6deg);
		box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.6);
	}

	&-text {
	    position: absolute;
	    left: 24%;
	    top: -111px;
	    width: 30%;
	}

	&-img {
	    position: absolute;
	    right: 13%;
	    width: 480px;
	    z-index: 8;
	    top: -80px;
	    box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);

		img {
			width: 100%;
			height: auto;
		}
	}

	h1 {
		font-family: $primary-font;
		margin-top: 0;
		margin-bottom: 20px;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 44px;
	}

	p {
		font-size: 15px;
	}

	&:before {
	    content: '';
	    position: relative;
	    display: block;
	    margin-top: -102px;
	    width: calc(100% + 16px);
	    height: 203px;
	    left: -19px;
	    background: $grey-light;
	    transform: rotate(6deg);
	}
}

@media (max-width: 1600px) {

	.frontpage-about {

		h1 {
			font-size: 39px;
		}

		&-img {
		    right: 13%;
		    width: 400px;
		    top: -80px;
		}

		&-gradient {
			left: 10%;
			width: 80%;
		}
	}
}

@media (max-width: 1400px) {

	.frontpage-about {

		h1 {
			font-size: 35px;
		}

		&-img {
			top: -111px;
			width: 300px
		}

		&-text {
			top: -150px;
		}

		&-gradient {
	    	height: 450px;
		}
	}
}

@media (max-width: $screen-md-max) {

	.frontpage-about {
		height: 350px;

		h1 {
			font-size: 25px;
		}
	}
}

@media (max-width: $screen-sm-max) {

	.frontpage-about {
		height: 350px;

		&-text {
			left: 17%;
			width: 42%;
		}

		&-img {
			width: 250px;
			right: 6%;
		}

		&-gradient {
			left: 5%;
			width: 90%;
	    	transform: skew(22deg, 6deg);
		}
	}
}

@media (max-width: $screen-xs-max) {

	.frontpage-about {
		height: 550px;

		h1 {
			font-size: 24px;
		}

		&-text {
			left: 10%;
			width: 80%;
			top: 0;
			@media (min-width: 375px) and (max-width: $screen-xs-max) {
				top: 70px;
			}
		}

		&-img {
		    width: 60%;
		    right: 20%;
		    top: -220px;
		}

		&-gradient {
			left: 0;
			top: -175px;
			width: 100%;
			height: 650px;
			-webkit-transform: skew(0, 8deg);
			-moz-transform: skew(0, 8deg);
			-ms-transform: skew(0, 8deg);
			-o-transform: skew(0, 8deg);
			transform: skew(0, 8deg);
		}
	}
}