body .cc_container .cc_btn,
body .cc_container .cc_btn:visited {
  background: $secondary-color;
  color: white;
}

body .cc_container {
  background: rgba(0, 0, 0, 0.8);

  .cc_more_info {
    color: $secondary-color;
  }
}