.timeline-year-title {
	float: left;
	display: block;
	width: 100%;
	font-size: 40px;
	font-weight: 700;
	text-transform: uppercase;
	font-family: $primary-font;
	color: $primary-color;
    margin-top: 40px;
    margin-bottom: 0px;

	&:first-child {
		margin-top: 0;
	}
}

.timeline-entry {
	margin-top: 30px;
	margin-bottom: 30px;
	width: 100%;
	float: left;
	position: relative;

	&::after {
		content: '';
		position: absolute;
	    width: 10px;
	    height: 215px;
		background: $primary-color;
	    left: 35px;
	    top: 11px;
	    z-index: 0;
		@media (max-width: $screen-xs-max) {
			height: 283px;
		}
	}
}

.last {
	.timeline-entry {

		&::after {
			content: '';
			position: absolute;
		    width: 0;
		    height: 0;
		}
	}
}

.timeline-entry-date {
	height: 80px;
	width: 80px;
	margin-right: 40px;
	margin-top: 10px;
	padding-top: 15px;
	background: $primary-color;
	border-radius: 100%;
	color: $white;
	text-align: center;
	float: left;
	position: relative;
	z-index: 2;
}

.timeline-date-day {
	font-size: 30px;
	line-height: 30px;
	display: block;
}

.timeline-date-month {
	font-size: 15px;
	line-height: 15px;
	font-weight: bold;
}

.timeline-entry-details {
	float: right;
	min-height: 150px;
	width: calc(100% - 120px);
	padding: 0;
	border: 1px solid #ddd;
	position: relative;
	border-radius: 5px;
	background: $white;
	z-index: 1;

	small {
		color: grey;
		font-size: 15px;
		padding-left: 0;
		line-height: 7px;
	}

	&:after {
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 15px 20px 15px 0;
		border-color: transparent #dddddd transparent transparent;
		position: absolute;
		left: -20px;
		top: 31px;
	}
}

.timeline-details-title {
	font-size: 35px;
	color: $primary-color;
	font-weight: 500;
	padding: 20px;
	display: block;
	line-height: 27px;

	img {
		width: 50px;
	}
}

.timeline-details-disziplin {
	font-size: 20px;
	color: $primary-color;
	font-weight: 300;
}

.timeline-details-country {
	font-size: 20px;
	color: $primary-color;
	font-weight: 300;
}

.timeline-entry-footer {
	padding: 0;
	border-top: 1px solid #ddd;
}

.timeline-entry-footer-entry {
	padding: 10px 20px;
	float: left;
	color: #222;
	font-weight: 300;
	border-right: 1px solid #ddd;
	font-size: 16px;

	img {
		max-height: 15px;
		margin-top: -2px;
	}
}

.timeline-entry-footer-desc {
	font-size: 10px;
	display: block;
	font-weight: bold;
}

@media (max-width: $screen-xs-max) {

	.timeline-entry-details {
		width: 100%;
		background: white;
		margin-top: 23px;

		&:after {
			display: none;
		}
	}

	.timeline-entry {
		margin-bottom: 0;

		&:after {
			left: calc(50% - 5px);
			top: 30px;
		}
	}

	.timeline-entry-footer-entry {
		font-size: 13px;
	}

	.timeline-entry-date {
		float: none;
		margin-left: auto;
		margin-right: auto;
	}

	.timeline-year-title {
		font-size: 30px;
	}
}