.scroll-indicator {
  position: absolute;
  left: calc(50% - 3px);
  top: 0;
  bottom: 0;
  width: 6px;
  background-image: url("/img/scroll-indicator.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}

.aboutme-entry {
  background: #eee;
  position: relative;
  box-shadow: 0 0 0 black;
  z-index: 1;
  transition: 0.3s;

  h2 {
    color: #000000;
    font-size: 40px;
    font-family: $primary-font;
    font-weight: bold;
    display: inline-block;

    @media(max-width: 1400px) {
      font-size: 30px;
      margin-top: 0;
    }

    &:after {
      content: "";
      height: 5px;
      background: $secondary-color;
      position: relative;
      display: block;
      width: auto;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  p {
    color: #616161;
    font-size: 25px;
    font-family: $primary-font;
    font-weight: lighter;

    @media(max-width: 1400px) {
      font-size: 18px;
    }

    @media(max-width: 1400px) {
      font-size: 14px;
    }

  }

  &-col {
    padding: 0;
  }

  &-year {
    position: absolute;
    bottom: 40px;
    right: 55px;
    color: #BDBDBD;
    font-size: 50px;
    font-family: $secondary-font;
    font-weight: bold;
    right: 55px;

    @media(max-width: 1400px) {
      right: 25px;
      bottom: 15px;
    }

    @media(max-width: $screen-sm-max) {
      right: 25px;
      font-size: 40px;
    }

    @media(max-width: $screen-xs-max) {
      top: 10px;
      right: 25px;
      bottom: auto;
      color: white;
      text-shadow: 0 0 10px black;
    }
  }

  &.active {
    background: #fff;
    z-index: 10;
    transition: 0.3s;

    @media(min-width: $screen-md-min) {
      box-shadow: 0 0 40px black;
    }
  }

  &:nth-child(even) {

    .aboutme-entry-year {
      right: calc(50% + 55px);

      @media(max-width: 1400px) {
        right: calc(50% + 25px);
      }

      @media(max-width: $screen-xs-max) {
        top: 5px;
        right: 25px;
        bottom: auto;
      }
    }

    .aboutme-entry-image {
      float: right;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  &-text {
    padding: 50px;

    @media(max-width: 1400px) {
      padding: 25px;
    }

    @media(max-width: $screen-sm-max) {
      padding: 15px;
    }
  }
}