.calendar-entry {
  border-radius: 5px;
  height: 350px;
  width: 100%;
  border: 1px solid #ddd;
  position: relative;
  margin-bottom: 30px;
  background: #fff;
  text-align: center;
  color: #616161;
  box-shadow: 0 0 0px #a0a0a0;
  transition: 0.5s;

  &:hover {
    box-shadow: 0 0 60px #a0a0a0;
    transition: 0.5s;

    img {
      box-shadow: 0 0 60px #eee;
      transition: 0.5s;
    }
  }

  &-date {
    font-size: 30px;
  }

  &-location {
    font-size: 20px;
  }

  &-flag {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;

    img {
      width: 100px;
      height: auto;
      box-shadow: 0 0 60px #a0a0a0;
      transition: 0.5s;
    }
  }
}