.footer {
	border-top: 1px solid #ddd;
}

.footer-top {
	min-height: 300px;
	background: $primary-color;
	margin-top: 70px;
}

.footer-sponsor {
	float: left;
	background: $white;
	width: 100%;
}

.footer-sponsor-thank {
	padding: 28px;
	color: $primary-color;
	float: left;
}

.footer-sponsor-entry {
	width: auto;
	padding: 20px;
	float: right;
	border-left: 1px solid #ddd;

	img {
		width: auto;
		height: 35px;
	}
}

@media (max-width: 2500px) {

	.footer-sponsor {
		text-align: center;
	}

	.footer-sponsor-thank {
		width: 100%;
		text-align: center;
		padding-bottom: 10px;
		padding-top: 10px;
	}

	.footer-sponsor-entry {
	    display: inline-block;
	    float: none;
	    border: 0;

	    img {
	    	height: 28px;
	    }
	}
}