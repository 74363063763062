.post-inner-wrapper {
  margin-top: 50px;
  float: left;
}

.post-entry {
  border: 2px solid #e5e5e5;
  transition: all .2s ease;
  box-shadow: 0 0 0;
  break-inside: avoid;
  margin-bottom: 30px;
  background: #fff;
  text-align: justify;
  height: 520px;

  &.xs-entry {
    height: 402px;

    @media(max-width: $screen-md-max) {
      height: 351px;
    }

    @media(max-width: $screen-sm-max) {
      height: 387px;
    }

    .post-entry-headline-wrapper {
      display: flex;
      height: 70px;
      align-items: center;
      justify-content: center;

      .post-entry-headline {
        height: auto;
        margin: 0;
      }
    }
  }

  .post-entry-link {
    color: white;
  }

  @media(max-width: $screen-md-max) {
    margin-bottom: 25px;
    height: 485px;

    .post-entry-text {
      height: 115px;
    }
  }

  @media(max-width: $screen-sm-max) {
    height: 509px;

    .post-entry-text {
      height: 100px;
    }
  }

  @media(max-width: $screen-xs-max) {
    height: auto;
  }

  &:hover {

    img {
      filter: gray;
      -webkit-filter: grayscale(100%);
      -webkit-transition: all .6s ease;
      -webkit-backface-visibility: hidden;
      z-index: 0;
    }

    .post-entry-img-plus {
      position: absolute;
      top: calc(50% - 35px);
      transition: 0.3s;
    }

    .post-entry-img-blur {
      transition: 0.5s;
      background: rgba(255, 4, 68, 0.75);
    }
  }

  a {
    &:focus {
      text-decoration: none;
    }
    &:hover {
      text-decoration: none;
    }
    &:active {
      text-decoration: none;
    }
  }

  &-img {
    position: relative;
    overflow: hidden;

    &-plus {
      position: absolute;
      left: 0;
      right: 0;
      top: -100px;
      bottom: 0;
      z-index: 2;
      color: $white;
      text-align: center;
      font-size: 50px;
      transition: 0.5s;
    }

    &-blur {
      background: rgba(0,0,0, 0);
      transition: 0.5s;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  &-inner {
    padding: 15px;
  }

  &-headline {
    margin: 0;
    margin-top: 5px;
    font-size: 22px;
    margin-bottom: 15px;
    font-family: $primary-font;
    font-weight: 300;
    text-transform: uppercase;
    color: #424242;
    text-align: center;
    height: 61px;
    display: block;

    &::after {
      content: '';
      position: relative;
      display: block;
      height: 3px;
      width: 30px;
      margin: auto;
      background: $secondary-color;
      margin-top: 10px;
    }
  }

  &-text {
    margin: 0;
    color: #616161;
    font-family: $secondary-font;
    font-weight: 300;
    display: block;
    height: 100px;
  }

  &-link {
    background: $secondary-color;
    padding-top: 13px;
    padding-bottom: 10px;
    text-align: center;
    color: white;
    font-size: 15px;
    line-height: 15px;
    font-family: $primary-font;
    font-weight: 300;
    text-transform: uppercase;
    display: block;
    color: white;
  }

  a {
    color: black;

    &:hover {
      text-decoration: none;
    }
  }
}

.modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

.modal-post {
  .modal-dialog {
    max-width: 800px;
    width: 100%;
    @media(max-width: $screen-md-max) {
      max-width: 700px;
      width: 100%;
    }
    margin-left: auto;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    background: #292725;

    img {
      width: 100%;
    }
  }

  .modal-header {
    background: #292725;
    border: 0;
    color: white;

    .modal-title {
      font-size: 20px;
      text-transform: uppercase;
      font-family: $secondary-font;
    }
  }

  .modal-footer {
    position: fixed;
    background: #292725;
    width: 100%;
    border-color: #585858;
    bottom: 0;
  }

  .modal-body {
    padding: 0;
    color: white;
    font-size: 17px;
    font-family: $secondary-font;
    font-weight: 300;
    height: calc(100vh - 120px);
    overflow: scroll;
  }

  h2 {
    padding: 20px;
    font-size: 25px;
    @media(max-width: $screen-xs-max) {
      font-size: 20px;
    }
    color: white;
    text-transform: uppercase;
    border-bottom: 1px solid #616161;
    font-family: $secondary-font;
    display: block;
    margin: 0;
  }

  .modal-header .close {
    color: white;
    margin-top: 0;
    opacity: 1;
  }

  .btn-link {
    color: white;
    text-transform: uppercase;

    &:hover {
      color: $secondary-color;
      text-decoration: none;
      text-transform: uppercase;
    }

    &:focus {
      outline: 0;
    }
  }

  .modal-body-inner {
    padding: 20px;
  }

  .modal-content {
    border-radius: 0;
    height: 100vh;
    background: #292725;
  }
}