.cover-full {
	background-size: cover;
	background-repeat: no-repeat;
	height: 105vh;
	position: relative;
	background-position: center top;

	/*&.cover-no-gradient {
		.cover-half-headline {
			background: rgba(0, 0, 0, 0);
		}
	}*/
}

.cover-scroll-down {
	position: absolute;
	font-size: 50px;
	width: 100%;
	text-align: center;
	top: 93vh;
	color: white;

	@media(max-width: $screen-sm-max) {
		top: 94vh;
	}

	@media(max-width: $screen-xs-max) {
		top: 92vh;
	}
}

.cover-full-about {
	color: white;
	font-size: 70px;
	text-transform: uppercase;
	position: absolute;
	left: 100px;
	top: 150px;
	display: block;
	font-family: $secondary-font;
	font-weight: 200;

	@media(max-width: 1400px) {
		font-size: 60px;
		top: auto;
		left: 50px;
		bottom: 120px;
		text-shadow: 0 0 10px black;
	}

	@media(max-width: $screen-xs-max) {
		font-size: 40px;
		left: 25px;
	}
}

.cover-full-about-sub {
	color: white;
	font-size: 45px;
	text-transform: uppercase;
	position: absolute;
	left: 100px;
	top: 230px;
	display: block;
	font-family: $secondary-font;
	font-weight: bold;

	@media(max-width: 1400px) {
		font-size: 38px;
		top: auto;
		left: 50px;
		bottom: 80px;
		text-shadow: 0 0 10px black;
	}

	@media(max-width: $screen-xs-max) {
		font-size: 25px;
		bottom: 90px;
		left: 25px;
	}
}

.cover-half {
	background-size: cover;
	background-repeat: no-repeat;
	height: 610px;
	position: relative;
	background-position: center top;

	/*&.cover-no-gradient {

		.cover-half-headline {
			background: rgba(0, 0, 0, 0);
		}
	}*/
}

.cover-half-headline {
	position: absolute;
	left: 0;
	right: 0;
	top: 80px;
	bottom: 0;
	font-size: 60px;
	color: $white;
	padding: 230px;
	height: 530px;
	text-transform: uppercase;
	font-weight: bold;
	z-index: 8;
	text-align: center;
	background: rgba(0, 0, 0, 0.5);
}

@media (max-width: $screen-md-max) {

	.cover-half {
		height: 400px;
		margin-top: 80px;
	}

	.cover-half-headline {
		height: 400px;
		font-size: 40px;
		padding: 0;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

}

@media (max-width: $screen-xs-max) {

	.cover-half {
		height: 250px;
		margin-top: 80px;
	}

	.cover-full-about {
		font-size: 32px;
	}

	.cover-full-about-sub {
		font-size: 20px;
		bottom: 98px;
	}

	.cover-half-headline {
		height: 250px;
		font-size: 32px;
		padding: 0;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}