.instagram-wrapper {
	padding-top: 0;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 25px; /* Chrome, Safari, Opera */
    -moz-column-gap: 25px; /* Firefox */
    column-gap: 25px;

	img {
		width: 100%;
		height: auto;
	}
}

.instagram-headline {
	padding-top: 0;
	padding-bottom: 60px;
}

.instagram-post {
	display: block;
	position: relative;
	border: 1px solid #ddd;
	background: $white;
	margin-bottom: 25px;    
	break-inside: avoid-column;
    -webkit-column-break-inside: avoid;
    float: left;

    &:nth-child(3n + 1) {
    	clear: left;
    }

}

.instagram-post-img {
	position: relative;
	text-align: center;

	.fa {
		position: absolute;
		font-size: 60px;
		left: 0;
		right: 0;
		top: calc(50% - 30px);
		color: rgba(0, 0, 0, 0.0);
		transition: 1s;
	}

	&:before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.0);
		transition: 1s;
	}

	&:hover {

		.fa {
			color: rgba(255, 255, 255, 1);
			transition: 1s;
		}

		&:before {
			background: rgba(0, 0, 0, 0.5);
			transition: 1s;
		}
	}
}

.instagram-post-text {
	padding: 15px;
	color: $primary-color;
}

@media (max-width: $screen-xs-max) {

	.instagram-wrapper {
	    -webkit-column-count: 1;
	    -moz-column-count: 1;
	    column-count: 1;
	}
}