body,
html {
	font-family: $primary-font;
	overflow-x: hidden;
	background: #eee;
}

.frontpage-body {
	background: #eee;
}

.primary-headline {
	font-family: $primary-font;
	margin: 0;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 40px;

	&.white {
		color: $white;
	}

	&.black {
		color: $primary-color;
	}

}

.main-wrapper {
	margin-top: 70px;
}

@media (max-width: $screen-xs-max) {

	.main-wrapper {
		margin-top: 20px;
	}
}